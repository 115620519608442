@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variable */

#root {
    --bgColor: rgba(17, 24, 39, 0.900);
    --bgLight: rgba(227, 227, 227, 0.9);
    --bgWhite: white;
    --textColor: white;
    --textBlack: black;
}

.main-con {
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 100px auto auto;
}

.header {
    grid-column: 3/17;
    grid-row: 1/1;
}

.aside {
    background-color: var(--bgLight);
    grid-row: 1/3;
    grid-column: 1/3;
    height: 100vh;
}

.section {
    background-color: var(--bgWhite);
    grid-column: 3/17;
    grid-row: 2/3;
}

.navList button {
    width: 100%;
    margin-top: 1em;
    background-color: var(--bgColor);
    padding: 13px;
    border-radius: 0px;
    font-weight: 800;
}

.login-con-bg {
    background-image: url(../public/assets/dbimgs/db3.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.css-1oqqzyl-MuiContainer-root {
    max-width: 100% !important;
}

#formatted-numberformat-input:disabled {
    color: white !important;
}


/* Home */

.home-con {
    grid-template-rows: 100px auto 50px;
    background-image: url(../public/assets/dbimgs/db3.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: wheat;
    position: relative;
}

.home-con::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(20deg, rgba(2, 80, 2, 0.706), rgba(1, 1, 82, 0.712));
}

.clnt-register-form input,
select,
option {
    padding: 1em;
    margin-bottom: 7px;
    margin-top: 7px;
    font-weight: 800;
}

.footer-home {
    background: linear-gradient(20deg, rgb(2, 46, 2), rgb(3, 3, 87));
    color: white;
    z-index: 1;
}

.con-dev>.css-1oqqzyl-MuiContainer-root {
    max-width: 100% !important;
}

.kssl-Pay-text {
    background-image: linear-gradient(20deg, rgb(2, 19, 2), rgb(2, 2, 16));
    color: transparent;
    background-clip: text;
}

.dash-text-wel {
    display: none !important;
}

.v-ul-list-p li {
    transition: 1s ease;
    display: flex;
    align-items: center;
}

.v-ul-list-p li:hover {
    background-image: linear-gradient(20deg, rgb(2, 19, 2), rgb(2, 2, 16));
    color: white;
}

.hide-refrsh-logout-btns-con {
    display: none;
}

.btn-add-mem {
    width: 100% !important;
    max-width: none;
    min-width: none;
    margin: "3px";
}

.in {
    position: "absolute";
    top: "9em";
}


/* Media quries */

@media all and (max-width: 1190px) {
    .header {
        grid-column: 1/17;
        grid-row: 1/1;
    }
    .aside {
        display: none;
    }
    .section {
        grid-column: 1/17;
        grid-row: 2/3;
    }
}

@media all and (max-width: 768px) {
    .header {
        grid-column: 1/17;
        grid-row: 1/1;
    }
    .aside {
        display: none;
    }
    .section {
        grid-column: 1/17;
        grid-row: 2/3;
    }
    .mem-top-point-con {
        flex-direction: column;
        padding: 4px;
    }
    .btn-add-mem {
        width: 100% !important;
        max-width: none;
        min-width: none;
        margin: "3px";
    }
    .add-btn-con-mmem {
        width: 100%;
        height: 100%;
    }
    .kssl-Pay-text {
        font-size: 5.3vw;
    }
    .dash-text {
        display: none !important;
    }
    .dash-text-wel {
        display: block !important;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        max-width: none !important;
        width: 100%;
        margin: 5px !important;
    }
    .hide-refrsh-logout-btns-con-header {
        display: none;
    }
    .hide-refrsh-logout-btns-con {
        display: block;
        display: flex;
        justify-content: space-between;
    }
    .btns-top-con-for-register {
        flex-direction: column;
        text-align: center;
    }
    .btns-top-con-for-register button {
        margin-top: 1em;
        width: 100%;
    }
}


/* Mobile  */

@media all and (max-width: 586px) {
    .add-btn-con-mmem {
        width: 100%;
        height: 100%;
    }
    .mem-top-point-con {
        flex-direction: column;
        padding: 4px;
    }
    .btn-add-mem {
        width: 100% !important;
        max-width: none;
        min-width: none;
        margin: "3px";
    }
    .kssl-Pay-text {
        font-size: 4.3vw;
    }
    .login-form-con {
        margin: 0 6px;
    }
    .login-form-con form {
        width: 100%;
    }
    .login-h-text {
        font-size: 7vw;
        text-align: center;
    }
    .m-d-page-con .css-ypiqx9-MuiDialogContent-root {
        padding: 0px 0px !important;
    }
    .d-d-m-p-con .css-ypiqx9-MuiDialogContent-root {
        padding: 0px 0px !important;
    }
}

@media print {
    .aside-id-show,.hide-at-prnt{
        display: none;
    }
    .aside {
       display: none;
    }
    #con-id-show-con  #con-id-show-con-inner {
        display: block !important;
    }
    .p-text-id-show{
        color: white !important;
    }
}